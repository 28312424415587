<template>
    <NoAuthLayout
        :submit="submit"
        :Welcome_Msg="Welcome_Msg">
        <input type="hidden" name="applicationId"
        :placeholder="Placeholder_ApplicationId"
        v-model="appId"
        @keyup="onChange" />
        <br/>
        <input type="hidden" name="applicationName"
        :placeholder="Placeholder_ApplicationName"
        v-model="appName"
        @keyup="onChange" />
        <br/>
        <input type="email" name="email"
        :placeholder="Placeholder_Email"
        v-model="email"
        @keyup="onChange" />
        <br/>
        <br/>
        <button class="button-warning-cus" type="submit"
            :disabled="disabled">
        {{Continue}}<span class="arrow"/>
        </button>
    </NoAuthLayout>
</template>

<script>
import { mapActions } from 'vuex';
import NoAuthLayout from '../layouts/noauth.layout';
import Dialog from '../utils/dialog';

export default {
    name: "BlockedAccount",
    components: {
        NoAuthLayout
    },
    data() {
        return {
            appId: '',
            appName: '',
            Continue: "Continuer",
            disabled: true,
            email: '',
            Placeholder_ApplicationId: "Id de l'application",
            Placeholder_ApplicationName: "Nom de l'application",
            Placeholder_Email: "Email",
            Popup_Success_Msg: "Votre compte est débloqué, vous pouvez vous reconnecter dès maintenant",
            Popup_Error_Msg: "Une erreur est survenue.",
            Welcome_Msg: "Lancement de la procédure de déblocage de votre compte."
        }
    },
    methods: {
        ...mapActions('user', ['unblock']),
        onChange() {
            this.disabled = !(this.appId && this.appName && this.email);
        },
        submit() {
            const dialog = new Dialog();
            this.disabled = true;
            this.unblock({
                applicationId: this.appId,
                applicationName: this.appName,
                email: this.email
            }).then((response) => {
                if (response === 'OK') {
                    dialog.alert(this.Popup_Success_Msg, {})
                        .then(() => {
                            this.$router.push('/');
                        });
                } else {
                    dialog.alert(this.Popup_Error_Msg, {});
                }
            }).catch((reason) => {
                console.error(reason);
                dialog.alert(this.Popup_Error_Msg, {});
            }).finally(() => {
                this.disabled = false;
            });
        }
    },
    mounted() {
        this.appId = this.$route.query.appId || '';
        this.appName = this.$route.query.appName || '';
        this.email = this.$route.query.email || '';
        this.onChange();
        if (!this.disabled) {
            this.submit();
        }
    }
}
</script>

<style scoped>

</style>